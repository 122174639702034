<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Testimonial</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\
                  Testimonial
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <a
                    @click="createTestimonial"
                    class="btn btn-primary font-weight-bolder font-size-sm"
                    v-if="checkIsAccessible('testimonial', 'create')"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Create New
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-col cols="12" md="5">
                <v-text-field
                  label="Title"
                  v-model="search.title"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" md="5">
                <v-select
                  :items="status"
                  v-model="search.is_active"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <button
                  class="btn btn-block btn-primary"
                  @click="searchTestimonial"
                >
                  Search
                </button>
              </v-col>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">
                      <strong>Title</strong>
                    </th>
                    <th class="px-3"><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="testimonials.length > 0">
                    <tr v-for="(item, index) in testimonials" :key="index">
                      <td class="px-2">
                        <a
                          @click="editTestimonial(item.id)"
                          class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          <v-avatar class="profile" color="grey" size="80">
                            <v-img
                              v-if="item.image_path"
                              :src="item.image_path.thumb"
                            ></v-img>
                          </v-avatar>
                          <span class="ml-3">{{ item.title }}</span>
                        </a>
                      </td>
                      <td class="px-2">
                        <span
                          class="badge text-lg"
                          v-bind:class="{
                            'badge-success': item.is_active,
                            'badge-danger': !item.is_active
                          }"
                        >
                          {{ item.is_active ? "Active" : "In Active" }}
                        </span>
                      </td>

                      <td class="pr-0 px-2 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click="editTestimonial(item.id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="blue darken-2"
                                      >fas fa-edit</v-icon
                                    >
                                  </span>
                                  <span class="navi-text">Edit Slider</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click.prevent="deleteTestimonial(item.id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="text-center" colspan="5">
                        <h5>No Data Found</h5>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <b-pagination
                v-if="testimonials.length > 0"
                class="float-right mt-7"
                @input="getTestimonial"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import TestimonialService from "@/service/cms/testimonial/TestimonialService";

const testimonialService = new TestimonialService();
export default {
  name: "Testimonial",
  data() {
    return {
      testimonials: [],
      page: null,
      total: null,
      perPage: null,
      search: {
        title: "",
        is_active: ""
      },
      status: [
        { title: "Active", value: 1 },
        { title: "In Active", value: 0 }
      ]
    };
  },
  mounted() {
    this.getTestimonial();
  },
  methods: {
    searchTestimonial() {
      this.getTestimonial();
    },
    getTestimonial() {
      testimonialService.paginate(this.search, this.page).then(response => {
        this.testimonials = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    createTestimonial() {
      this.$router.push({
        name: "testimonial-create"
      });
    },
    editTestimonial(testimonialId) {
      this.$router.push({
        name: "testimonial-update",
        params: { id: testimonialId }
      });
    },
    sort() {
      testimonialService
        .sort(this.testimonial)
        .then(response => {
          if (response.data.status === "OK") {
            this.$snotify.success("Sorted");
            this.getTestimonial();
          }
        })
        .catch(() => {
          this.$snotify.success("Sorted Error");
        });
    },
    deleteTestimonial(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            testimonialService
              .delete(id)
              .then(response => {
                if (response.data.status === "OK") {
                  this.$snotify.success("Deleted successfully");
                  this.getTestimonial();
                }
              })
              .catch(() => {});
          }
        }
      });
    }
  }
};
</script>
